.screen {
    height: 100vh;
    width: 100%;
}

/* Left screen */
.left-screen {
    width: 60%;
    height: 100%;

    left: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery-img {
    width: 100%;
    height: 95%;
    object-fit: cover;
}

/* Right screen */
.right-screen {
    width: 40%;
    height: 100%;
    
    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 2rem 4rem;
    
}

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 500px;
    max-height: 100%;

    padding: 0 10%;
    padding-bottom: 5%;
    
    border-radius: 20px;
    backdrop-filter: blur(10px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient( 174.2deg,  rgba(241,238,223,0.2) 7.1%, rgba(224, 215, 186, 0.2), rgba(211,198,169,0.4) 67.4% );
}

/* Logo */
.header {
    display: flex;
    flex-direction: row;
    justify-content: center;

    max-width: 100%;
}

.thumbnail-img {
    max-width: 50%;
    max-height: 50%;
    display: block;
    filter: invert(1000%) sepia(200%) saturate(100%) hue-rotate(296deg) brightness(90%) contrast(50%);
}

@media screen and (max-width: 600px) {
    body {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .left-screen {
        width: 100%;
    }

    .right-screen {
        width: 100%;
        height: 100%;

        padding: 0 5%;
        margin: 0;
        overflow: auto;
    }

    .content-container {
        position: relative;
        height: fit-content;

        backdrop-filter: blur(20px);
    }

    h1 {
        font-size: 8vmin;
    }
}