.App {
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.headtext__major {
  font-family: var(--font-system);
  color: var(--color-black);
  font-size: 33px;
}

.headtext__minor {
  font-family: var(--font-system);
  color: var(--color-gray);
  font-size: 14px;
}

.headtext__info {
  font-family: var(--font-system);
  font-size: 13px;
  font-weight: 300;

  color: var(--color-black);
}

.headtext__custom {
  font-family: var(--font-major);
  color: var(--color-black);
}

button {
  font-family: var(--font-system);
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  
  border-radius: 8px;
  border: 2.5px solid;
  padding: 0 5px;
  
  cursor: pointer;

  min-height: 40px;
  min-width: fit-content;
  /* max-width: 200px; */
}

.system-button-primary {
  background-color: #4F4F4F;
  border-color: #4F4F4F;
  color: #F5F5F5;
}

.system-button-secondary {
  background: #fff;
  color: var(--color-gray);
  border-color:  rgba(197, 199, 212, 0.5);
}

.custom-button {
  background: #C4B098;
  border-color: #C4B098;
  color: var(--color-white);
}

button:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.display-none {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
