.slide-item {
    height: 100vh
}
.slide-background-div {
    display: flex;
    text-align: center;
    justify-content: center;
    overflow: hidden; 

    height: 100vh;
    width: 100vw;
}

#slide-background {
    height: 100vh;
    position: relative;
    z-index: 0;

    object-position: center;
    object-fit: cover;
    opacity: 0.8;
    filter: blur(10px);
}

#slide-img {
    height: 80vh;
    object-fit: contain;
    padding-top: 3%;
}

.position-absolute {
    position: absolute;
    top: 0;
    left: 0;
}


.title-span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    position: absolute;
    bottom: 0;

    width: 100vw;
    height: 20vh;
    padding: 0 2%;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.title-span .title {
    font-family: var(--font-major);
    color: var(--color-seasalt);

    font-size: 36px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.title-span .description {
    font-family: var(--font-minor);
    color: var(--color-platinum);

    font-size: 14px;
    letter-spacing: 0.04em;
}

.back-button {
    position: absolute;
    top: 2%;
    left: 2%;
    z-index: 999;
    
    background-color: transparent;
    border: none;
}