.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: 50%;
    padding: 2rem 2rem;
}

.hostroom-button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hostroom-button-group button {
    width: 250px;
}

.second-button-group {
    display: flex;
    justify-content: center;
}

.view-room-button {
    background-color: transparent;
    border: none;
    color: black;
    text-decoration: underline;

    max-width: 50%;
}

.view-room-button:hover {
    box-shadow: none;
}

.img {
    max-width: 50%;
}