@import url('https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&family=Inter:wght@100;300;400&family=Poppins&family=Taviraj:wght@100&display=swap');

:root {
  --font-system: 'Inter', sans-serif;
  --font-major: 'Goudy Bookletter 1911', serif;
  --font-minor: 'Taviraj', serif;

  --color-golden: #ffd100;
  --color-brown: #b2967d;
  --color-gray: #b9b9b9;
  --color-black: #0C0C0C;
  --color-white: #fff;
  --color-seasalt: #f8f9fa;
  --color-platinum: #dee2e6;
  --color-beige: #FCE7DE;
  --color-pink: #D5BEC2;
  --color-nude: #cca290;

  width: 100vw;
  height: 100vh;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}