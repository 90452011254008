header {
    background-color: bisque;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-top: 0;
    height: 13vh;
    width: 100vw;
  }
  
  
  nav ul {
    display: flex;
  }
  
  
  nav li {
    margin-right: 1rem;
  }
  
  
  nav li:last-child {
    margin-right: 0;
  }
  
  
  nav a {
    padding: 0.5rem;
  }
  
  
  .body{
    background-color: ivory;
  }
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vh;
    background: ivory;
   
    border-radius: 20px;
    border: bisque solid 1px;
    box-shadow: 0cqh 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 20px 60px 30px 40px;
    position: relative;
    height: 70vh;
    bottom: -20px;
  
  
  }
  .contact-container h1 {
    text-align: center;
    color: peru;
  }
  .contact-container p{
    margin-top: 40px;
   
  }
  .contact-container .left-side {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;
    position: absolute;
    font-size: 16px;
    margin-left: -100px;
    [class = "topic"]{
      margin-left: 25px;
  
  
    }
    .iconMap{
      margin-left: 25px;
      color: burlywood;
     
    }
    .iconPhone{
      margin-left: 25px;
      color: burlywood;
    }
    .iconEmail{
      margin-left: 25px;
      color: burlywood;
    }
   
  }
  .contact-container .line {
    position: absolute;
    border-left: 5px solid burlywood;
    height: 350px;
    width: 2px;
    margin-left: -250px;
    bottom: 50px;
  }
  .contact-container .right-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    position: absolute;
    font-size: 16px;
    margin-left: 400px;
   
  }
  .right-side.submit{
    width: 40%;
  }
  input{
    width: 50%;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    height: 35px;
    outline: none;
    border: 1px solid rgb(220, 220, 220);
  
  
    &:focus {
      border: 2px solid peru;
    }
  }
  textarea{
    max-height: 120px;
    min-height: 120px;
    max-width: 50%;
    min-width: 50%;
    padding: 7px;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
  
  
    &:focus {
      border: 2px solid peru;
    }
  }
  
  
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
    font-size: 16px;
    width: 75%;
    margin-left: 100px;
     
  }
  input[type="submit"] {
    /* margin-top: 2rem; */
    width: 15%;
    height: 40px;
    cursor: pointer;
    background: burlywood;
    color: white;
    border: none;
   
  }
  input[type="submit"]:hover {
    background-color: peru;
  }
  .invalid {
    border-color: red;
  }
  
  
  
  
  
  
  
  
  html, body {
    height: 90%;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  
  /* Header styles */
  header {
    background-color: bisque;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-top: 0
  }
  
  
  nav ul {
    display: flex;
  }
  
  
  nav li {
    margin-right: 1rem;
  }
  
  
  nav li:last-child {
    margin-right: 0;
  }
  
  
  nav a {
    padding: 0.5rem;
  }
  
  
  /* Main styles */
  main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    min-height: 75vh ;
    background-color: ivory;
   
  }
  
  
  .intro {
   
    width: 500px;
    padding-top: 100px;
    padding-bottom: 50px;
    padding-left: 10px;
    border: 5px solid gray;
    align-items: center;
    margin-top: -60px;
   
    background-color: bisque;
  }
  
  
  .intro h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    align-items: center;
    margin-left: 50px;
    margin-top: -100px;
  }
  
  
  .intro p {
    font-size: 1.2rem;
    margin-left: 50px;
  }
  .mv-section{
    width: 500px;
    padding: 90px;
    /* padding: bottom -10px; */
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border: 5px solid gray;
    margin-right: 800px;
    margin-top: 50px;
    background-color: bisque;
  }
  
  
  .v-section{
    width: 500px;
    padding: 90px;
    /* padding: bottom -10px; */
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border: 5px solid gray;
    margin-left: 800px;
    margin-top: -180px;
    background-color: bisque;
  
  
  }
  
  
  .mission-vision-values h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
   
  }
  
  
  .mission-vision-values {
    margin-bottom: 2rem;
  }
  
  
  .mission-vision-values h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  
  .mission-vision-values ul {
    padding-left: 1rem;
  }
  
  
  /* Footer styles */
  footer {
    background-color: cornsil;
    color: #fff;
    text-align: center;
    padding: 1rem;
  }
  
  
  footer p {
    margin: 0;
  }
  
  
  
  
  