
.form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    width: 100%;
}

.form-wrapper .form-box.login {
    transition: transform .18s ease;
    transform: translateX(0);
}

.form-wrapper.active .form-box.login {
    transition: none;
    transform: translateX(-400px);
}

.form-wrapper .form-box.register {
    position: absolute;
    transition: none;
    transform: translateX(1000px);
}

.form-wrapper.active .form-box.register {
    transition: transform .18s ease;
    transform: translateX(0px);

    max-width: 100%;
    padding: 0 2rem;
}

/* Login form */
form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
}

form h1 {
    font-weight: 250;
    text-shadow: 1px 1px 2px var(--color-brown);
}

.input-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    gap: 5px;
    width: 100%;
}

.input-field {
    position: relative;
    display: flex;
    flex-direction: column;

    border-top: 20px solid transparent;
    
    gap: 5px;
    width: 100%;
}

.input-field input {
    max-width: 100%;
    position: relative;
    padding: 12px;

    font-size: 14px;

    background: transparent;
    border: none;
    border-bottom: 1px solid var(--color-gray);
    outline-color: var(--color-gray);
}

.button-group {
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: 10px;
    padding: 5% 5%;
}

.system-button {
    width: 100%;
    height: 100%;
    min-height: 40px;
}

.line-group {
    display: flex;
    align-items: center;

    padding: 1rem 0rem;
    width: 100%;
}

.straight-line {
    flex: 1;
    width: 125px;
    height: 1px;
    background-color: var(--color-gray);
}

.login-with-google-btn {
    transition: background-color .3s, box-shadow .3s;

    border: none;
    border-radius: 10px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .1);
    
    max-height: 40px;
    width: 80%;

    font-size: 14px;
    font-weight: 500;
    
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: #fffffc;
    background-repeat: no-repeat;
    background-position: 10% 50%;
}

.login-register {
    margin: 25px 0 10px;
}

.login-register p a:hover {
    text-decoration: underline;
}
