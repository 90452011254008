.mainpage-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-image: linear-gradient(to top, #cca290, #d0a69a, #d2aba4, #d4b0ae, #d5b6b7, #d9bcbd, #dcc2c3, #e0c8c9, #e8d0ce, #efd7d3, #f6dfd8, #fce7de); */
  }
  
  .modal-wrapper {
    display: flex;
    overflow-y:auto;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 4rem;
    width: 100%;
  }
  
  .modal-box {
    width: 40%;
    overflow-y:auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .modal-title {
    display: flex;
    justify-content: center;
    font-weight: 800;
  }
  
  .button-group-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 1rem 0;
  }
  
  .img-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .modal-wrapper img {
    max-width: 100%;
    height: auto;
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    max-height: 100%;
    gap: 1rem;
    padding: 1rem 0;
  }
  
  .headtext {
    font-weight: 800;
    font-size: 2.5rem; /* Adjust font size as needed */
  }
  