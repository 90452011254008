.modal-background {
    width: 100vw;
    height: auto;
    background-color: rgba(242, 242, 242, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
}



.modal {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 400px;
    padding: 25px;
    max-height: 90vh;
    /* gap: 1rem */
}



.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    width: 90%;
    gap: 1rem;
    margin: 1rem 0;
}

.modal .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
}

.close-button {
    transform: translateX(800%) translateY(-400%);
    cursor: pointer
}

.modal-content button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    width: 100%;
    height: 50px;

    background-color: transparent;
}

.modal-content .active {
    border: var(--color-black);
}

.inactive {
    border: none;
    color: var(--color-gray);
}

.duration-div {
    border: none;
}

.duration-div:hover {
    box-shadow: none;
}

.duration-input {
    width: 100px;
    height: 35px;
    padding: 0 0.5rem;

    border-radius: 5px;
    border: 1px solid var(--color-black);
}