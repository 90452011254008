#host-history-section {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.host-history-wrapper {
    max-width: 80%;
    z-index: 1;

    margin-top: 4rem;
    margin-left: 4rem;
    padding: 2rem 2rem;

    border-radius: 20px;
    backdrop-filter: blur(10px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
}

.room-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 0 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    gap: 20px;
    /* max-width: 30%; */
    /* min-width: 30%; */

    border-radius: 15px;
    border: 1.5px solid rgba(197, 199, 212, 0.5);
}

.room-card img {
    width: 50%;
}

.modal button {
    width: 75px;
}