.art-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.art-image {
  width: 50%;
}

.art-title-input {
  width: 70%;
  padding: 12px;
  font-size: 14px;
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--color-gray);
  outline-color: var(--color-gray);
}

.upload-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: var(--color-primary-dark);
}
